<template>
  <span>
    <span class="btn btn-edit" qid="edit-tp-modal" @click="showEditPModal(object)">
      <font-awesome-icon icon="pen"/>
    </span>

    <!-- Edit Process modal template -->
    <b-modal ref="edit-process" hide-footer @hide="hideEditPModal">
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('risk_scenarios.update.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="formSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-name-label">
              {{ $t('risk_scenarios.create.name_label') }}
            </label>
            <b-form-input
              qid="create-p-form-name"
              type="text"
              v-model="pForm.name"
              :maxLength="255"
              required
              :placeholder="$t('risk_scenarios.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-do-tags-label">
              {{ $t('processes.create.tags_label') }}
            </label>
            <multiselect
                    v-model="selectedTags"
                    :options="tags"
                    :multiple="true"
                    :taggable="true"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    @search-change="loadTags"
                    track-by="name_translated"
                    :placeholder="$t('processes.create.tags_placeholder')"
                    label="name_translated"
                    qid="create-do-tags-select"
                    @tag="addTag"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-p-pg-label">
              {{ $t('risk_scenarios.create.risk_category_label') }}
            </label>
            <multiselect
                v-model="riskCategory"
                :options="riskCategories"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('risk_scenarios.create.risk_category_placeholder')"
                label="name"
                track-by="id"
                @search-change="loadRiskCategories"
                qid="create-p-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-tp-type-label">
              {{ $t('table.scope') }}
            </label>
            <multiselect
                v-model="selectedTypes"
                :options="types"
                :multiple="true"
                track-by="value"
                :taggable="true"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('table.scope')"
                label="name"
                qid="create-tp-form-type"
            >
              <template slot="noResult" slot-scope="props">
                {{ $t('maintenance.data_subjects.create.subjects_no_results') }}
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-tp-type-label">
              {{ $t('risk_scenarios.create.risk_type_label') }}
            </label>
            <multiselect
                v-model="selectedRiskTypes"
                :options="riskTypes"
                :multiple="true"
                track-by="value"
                :taggable="false"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('risk_scenarios.create.risk_type_placeholder')"
                label="name"
                qid="create-tp-form-type"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('risk_scenarios.create.owners_label') }}
            </label>
            <multiselect
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                :taggable="false"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadUsers"
                @remove="removeUserFromDropdown"
                track-by="slug"
                :placeholder="$t('risk_scenarios.create.owners_placeholder')"
                label="name"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span></span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
            <div class="form-control auto-height mt-1" v-if="selectedUsers && selectedUsers.length">
              <div v-for="(user, index) in selectedUsers" class="mt-1 mb-1">
                <div class="row">
                  <div class="col-sm-1">
                    <font-awesome-icon
                        icon="star"
                        :class="{'color-yellow': user.owner, 'text-light': !user.owner}"
                        class="cursor-pointer"
                        @click="setOwner(index)"
                    />
                  </div>
                  <div class="col-sm-10">
                    <Avatar :object="user" size="xs" :noOwner="true"></Avatar>
                    <span class="ml-2">{{ user.name }}</span>
                  </div>
                  <div class="col-sm-1">
                    <font-awesome-icon
                        icon="times"
                        class="cursor-pointer"
                        @click="removeUser(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span class="text-light">
              * {{ $t('risk_scenarios.create.owners_tip') }}
            </span>
          </b-form-group>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditPModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('risk_scenarios.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('risk_scenarios.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </span>



</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'ActivityEditForm',
    data: function() {
      return {
        pForm: {
          name: '',
          description: '',
          types: [],
          risk_types: [],
          users: [],
          tags: [],
          risk_category_id: ''
        },
        selectedTags: [],
        error: '',
        selectedUsers: [],
        loadingItems: false,
        users: [],
        tags: [],
        newTags: [],
        editParam: false,
        countries: [],
        selectedCountries: [],
        modalShown: false,
        buttonDisabled: false,
        types: [
          { name: this.$t('risk_scenarios.type.process'), value: 'process' },
          { name: this.$t('risk_scenarios.type.activity'), value: 'activity' },
          { name: this.$t('risk_scenarios.type.third_party'), value: 'third_party' }
        ],
        riskTypes: [
          { name: this.$t('risk_scenarios.risk_type.business_risk'), value: 'business_risk' },
          { name: this.$t('risk_scenarios.risk_type.privacy_risk'), value: 'privacy_risk' }
        ],
        selectedTypes: [],
        selectedRiskTypes: [],
        riskCategory: [],
        riskCategories: [],
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      edit: {
        type: Boolean,
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadTags();
        this.loadUsers();
        this.loadRiskCategories();
      },
      object: function (value) {
        let self = this;
        if (value && value.id) {
          if (this.editParam && !this.modalShown) {
            setTimeout(function(){
              self.showEditPModal(self.object)
              self.modalShown = true;
            }, 500);
          }
        }
      }
    },
    methods: {
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      showEditPModal(object) {
        let self = this;

        if (this.getClient.systemModule) {
          this.types.push({ name: this.$t('risk_scenarios.type.system'), value: 'system' },)
        }

        this.selectedTags = []
        this.selectedUsers = []

        this.loadTags();
        this.loadUsers();
        this.loadRiskCategories();

        this.riskCategory = object.risk_category;

        if (object.users && object.users.length) {
          self.selectedUsers = [];
          _.forEach(object.users, function (user) {
            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedUsers.push(matchedUser)
          })
        }

        if (object.types && object.types.length) {
          self.selectedTypes = [];
          _.forEach(object.types, function (value, key) {
            _.find(self.types, function(item) {
              if (item.value === value) {
                self.selectedTypes.push(item)
              }
            });
          })
        }

        if (object.risk_types && object.risk_types.length) {
          self.selectedRiskTypes = [];
          _.forEach(object.risk_types, function (value, key) {
            _.find(self.riskTypes, function(item) {
              if (item.value === value) {
                self.selectedRiskTypes.push(item)
              }
            });
          })
        }

        if (object.tags_translated && object.tags_translated.length) {
          this.selectedTags = _.cloneDeep(object.tags_translated);
        }

        this.pForm.slug = object.slug;
        this.pForm.name = object.name;
        this.pForm.description = object.description;
        this.$refs['edit-process'].show()

      },
      hideEditPModal() {
        this.pForm = {}
        this.error = '',
        this.selectedUsers = [],
        this.editParam = false;
        this.$refs['edit-process'].hide()
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let selectedOwner = false;
        evt.preventDefault();

        this.pForm.tags = [];
        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            this.pForm.tags.push(this.selectedTags[i].name_translated);
          }
        }

        this.pForm.users = [];
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            this.pForm.users.push(this.selectedUsers[i].id);
            if (this.selectedUsers[i].owner) {
              selectedOwner = true;
              this.pForm.owner = this.selectedUsers[i].id;
            }
          }
        } else {
          selectedOwner = true;
        }

        if (this.riskCategory) {
          this.pForm.risk_category_id = this.riskCategory.id;
        } else {
          this.pForm.risk_category_id = ''
        }

        this.pForm.types = [];
        if (this.selectedTypes && this.selectedTypes.length) {
          for (var i = 0; i < this.selectedTypes.length; i++) {
            this.pForm.types.push(this.selectedTypes[i].value);
          }
        }

        this.pForm.risk_types = [];
        if (this.selectedRiskTypes && this.selectedRiskTypes.length) {
          for (var i = 0; i < this.selectedRiskTypes.length; i++) {
            this.pForm.risk_types.push(this.selectedRiskTypes[i].value);
          }
        }

        if (selectedOwner && this.selectedTypes && this.selectedTypes.length && this.selectedRiskTypes && this.selectedRiskTypes.length) {
          piincHttp.put('risk-scenarios/' + this.pForm.slug, this.pForm).then(function(response) {
            self.hideEditPModal();
            // Emit event to parent component to update newest validation
            self.$emit('reloadContent', response.data);
            self.$toastr('success', self.$t('risk_scenarios.update.success'))
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = ''
          if (!selectedOwner) {
            this.error += this.$t('system.owners_error_message')
          }
          if (this.selectedTypes && !this.selectedTypes.length) {
            this.error +=  ' ' + this.$t('system.type_error_message')
          }
          if (this.selectedRiskTypes && !this.selectedRiskTypes.length) {
            this.error +=  ' ' + this.$t('system.risk_type_error_message')
          }
          this.buttonDisabled = false;
        }
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
                  {
                    client: this.getClient.slug,
                    keyword: query ? query : undefined
                  }
        }).then(function(response) {
          self.tags = response.data
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadRiskCategories(query) {
        let self = this;
        piincHttp.get('risk-categories', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined,
            per_page: 100
          }
        }).then(function(response) {
          self.riskCategories = response.data.items
          if (self.riskCategories && !self.riskCategories.length && !query) {
            self.riskCategories.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      }
    },
    components: {
      Avatar
    },
    created () {
      this.editParam = this.edit;
      let self = this;
      if (this.object && this.object.id) {
        if (this.editParam && !this.modalShown) {
          setTimeout(function(){
            self.showEditPModal(self.object)
            self.modalShown = true;
          }, 500);
        }
      }
      if (this.getClient && this.getClient.slug) {

      }
    }
  }
</script>
