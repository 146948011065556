<template>
  <span>
    <span class="btn btn-edit" :class="buttonClass" qid="edit-tp-modal" @click="showEditPModal(object)">
      <font-awesome-icon icon="pen"/>
    </span>

    <!-- Edit Process modal template -->
    <b-modal ref="edit-process" @hide="hideEditPModal" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditPModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-tp-title">
            {{ $t('processes.update.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <b-form qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-name-label">
              {{ $t('processes.create.name_label') }}<span class="mandatory"> *</span>
            </label>
            <b-form-input
              qid="create-p-form-name"
              type="text"
              v-model="pForm.name"
              :maxLength="255"
              required
              :placeholder="$t('processes.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <label qid="create-p-pg-label">
              {{ $t('processes.create.process_group_label') }}
            </label>
            <multiselect
                v-model="processGroup"
                :options="processGroups"
                @search-change="loadProcessGroups"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('processes.create.process_group_placeholder')"
                label="name"
                track-by="slug"
                qid="create-p-form-pg"
            ></multiselect>
          </b-form-group>

          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('processes.create.owners_label') }}
            </label>
            <multiselect
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                :taggable="false"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadUsers"
                @remove="removeUserFromDropdown"
                @select="addUser"
                track-by="slug"
                :placeholder="$t('processes.create.owners_placeholder')"
                label="name"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span></span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
            <div class="form-control auto-height mt-1" v-if="selectedUsers && selectedUsers.length">
              <div v-for="(user, index) in selectedUsers" class="mt-1 mb-1">
                <div class="row">
                  <div class="col-sm-1">
                    <font-awesome-icon
                      icon="star"
                      :class="{'color-yellow': user.owner, 'text-light': !user.owner}"
                      class="cursor-pointer"
                      @click="setOwner(index)"
                    />
                  </div>
                  <div class="col-sm-10">
                    <Avatar :object="user" size="xs" :noOwner="true"></Avatar>
                    <span class="ml-2">{{ user.name }}</span>
                  </div>
                  <div class="col-sm-1">
                    <font-awesome-icon
                      icon="times"
                      class="cursor-pointer"
                      @click="removeUser(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span class="text-light">
              {{ $t('processes.create.owners_tip') }}
            </span>
          </b-form-group>

          <b-form-group>
            <label qid="create-do-tags-label">
              {{ $t('processes.create.tags_label') }}
            </label>
            <multiselect
                v-model="selectedTags"
                :options="tags"
                :multiple="true"
                :taggable="true"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadTags"
                track-by="name_translated"
                :placeholder="$t('processes.create.tags_placeholder')"
                label="name_translated"
                qid="create-do-tags-select"
                @tag="addTag"
            ></multiselect>
          </b-form-group>

          <h5 class="mb-3 mt-4">
            <label>{{ $t('maintenance.third_parties.create.features_section_label') }}</label>
          </h5>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.privacy_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                      v-model="pForm.privacy_feature"
                      :options="checklistOptions"
                      qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.assessment_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                      v-model="pForm.assessment_feature"
                      :options="checklistOptions"
                      qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideEditPModal"
                            qid="create-p-cancel-button"
                  >
                    {{ $t('processes.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            @click="formSubmit"
                            :disabled="buttonDisabled"
                            qid="create-p-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('processes.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </span>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'ProcessEditForm',
    data: function() {
      return {
        pForm: {
          name: '',
          users: '',
          tags: [],
          users: [],
          process_group_id: '',
          assessment_feature: 1,
          privacy_feature: 1,
        },
        selectedTags: [],
        error: '',
        selectedUsers: [],
        processGroup: [],
        loadingItems: false,
        users: [],
        tags: [],
        processGroups: [],
        newTags: [],
        editParam: false,
        modalShown: false,
        buttonDisabled: false,
        checklistOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0},
        ],
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      edit: {
        type: Boolean,
      },
      buttonClass: {}
    },
    components: {
      Avatar
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadUsers();
        this.loadTags();
        this.loadProcessGroups();
      },
      object: function (value) {
        let self = this;
        if (value && value.id) {
          if (this.editParam && !this.modalShown) {
            setTimeout(function(){
              self.showEditPModal(self.object)
              self.modalShown = true;
            }, 500);
          }
        }
      }
    },
    methods: {
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      addUser(selectedItem, id) {
        // If user is the first to be added to the list it will be auto-marked as owner
        if (this.selectedUsers.length == 0) {
          selectedItem.owner = true;
        }else{
          selectedItem.owner = false;
        }
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      showEditPModal(object) {
        let self = this;
        this.error = ''
        this.selectedTags = []
        this.selectedUsers = []

        this.loadUsers();
        this.loadTags();
        this.loadProcessGroups();

        this.processGroup = object.process_group;

        if (object.users && object.users.length) {
          self.selectedUsers = [];
          _.forEach(object.users, function (user) {
            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedUsers.push(matchedUser)
          })
        }

        if (object.tags_translated && object.tags_translated.length) {
          this.selectedTags = _.cloneDeep(object.tags_translated);
        }

        this.pForm.privacy_feature = object.privacy_feature;
        this.pForm.assessment_feature = object.assessment_feature;
        this.pForm.slug = object.slug;
        this.pForm.name = object.name;
        this.$refs['edit-process'].show()

      },
      hideEditPModal() {
        this.newTags = []
        this.$refs['edit-process'].hide()
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let selectedOwner = false;
        evt.preventDefault();

        this.pForm.tags = [];
        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            this.pForm.tags.push(this.selectedTags[i].name_translated);
          }
        }

        this.pForm.users = [];
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            this.pForm.users.push(this.selectedUsers[i].id);
            if (this.selectedUsers[i].owner) {
              selectedOwner = true;
              this.pForm.owner = this.selectedUsers[i].id;
            }
          }
        } else {
          selectedOwner = true;
        }

        if (this.processGroup) {
          this.pForm.process_group_id = this.processGroup.id;
        } else {
          this.pForm.process_group_id = ''
        }

        if (selectedOwner && this.pForm.name && this.pForm.name.length) {
          piincHttp.put('processes/' + this.pForm.slug, this.pForm).then(function(response) {
            self.hideEditPModal();
            self.buttonDisabled = false;
            // Emit event to parent component to update newest validation
            self.$emit('reloadContent', response.data);
            self.$toastr('success', self.$t('processes.update.success'))
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = ''
          if (!selectedOwner) {
            this.error += this.$t('system.owners_error_message')
          }
          if (!this.pForm.name) {
            this.error += this.$t('system.name_error_message')
          }
          this.buttonDisabled = false;
        }
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.tags = response.data
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
        }, function() {});
      },
      loadProcessGroups(query) {
        let self = this;
        piincHttp.get('process-groups', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.processGroups = response.data.items
          if (self.processGroups && !self.processGroups.length && !query) {
            self.processGroups.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      }
    },
    created () {
      this.editParam = this.edit;
      let self = this;
      if (this.object && this.object.id) {
        if (this.editParam && !this.modalShown) {
          setTimeout(function(){
            self.showEditPModal(self.object)
            self.modalShown = true;
          }, 500);
        }
      }
    }
  }
</script>
